.intro {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  > img.bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  >img.logo {
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 190px;
  }

  > button.begin {
    position: absolute;
    top: 800px;
    left: 800px;
    opacity: 0;
    width: 400px;
    height: 110px;
    border-radius: 100px;
    outline: 0;
    border: 0;
    background: white;
    cursor: pointer;
  }
}
