.game {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  > div.smallLogo {
    position: absolute;
    right: 0px;
    bottom: 13px;
    z-index: 255;
    background: white;
    border-radius: 15px 0 0 15px;
    padding: 25px;

    > img {
      height: 60px;
    }
  }

  > img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  > div.windows {
    position: absolute;
    top: 330px;
    left: 995px;
    width: 910px;
    height: 635px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 55px;

    > div {
      box-sizing: border-box;
      padding: 25px;
      display: grid;
      align-items: center;
      color: #006bb1;
      font-size: 25px;
    }

    ol {
      margin: 0;
    }
  }

  > div.wheelContainer {
    position: absolute;
    top: 241px;
    left: 60px;
    width: 802px;
    height: 802px;
    border-radius: 100%;
    overflow: hidden;

    &.clickable {
      cursor: pointer;
      transition: transform 0.25s, box-shadow 0.25s;

      /*&:hover {
        transform: scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
      }*/
    }

    > img {
      &.outerWheel,
      &.innerWheel {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        transform-origin: center;
        transition: transform 5s;
      }
      &.innerWheel {
        /*filter: invert(100%);*/
        clip-path: circle(275px);
      }
      &.spin {
        position: absolute;
        top: calc(50% + 14px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
      }
      &.pointer {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
      }
    }
  }

  > div.screen {
    position: absolute;
    top: 50px;
    left: 1130px;
    width: 647px;
    height: 200px;

    > div.countdown {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #d3402a;

      > h2 {
        margin: 0;
        font-size: 22px;
      }

      > h3 {
        margin: 0;
        font-size: 60px;
        font-weight: bold;

        &.inTheGood {
          color: #006b16;
        }
      }
    }
  }
}
